@import "~@evrone-erp/evrone-erp-ui/lib/css/bootstrap/imports";

.instanceCard {
  + .instanceCard {
    margin-top: 10px;
  }

  &__content {
    display: flex;
    align-items: center;
    padding: 5px;
  }

  &__name {
    margin-right: auto;
  }

  &__url {
    margin-left: auto;
    margin-right: 25px;
  }

  &__editBtn {
    margin-right: 5px;
  }
}
