.input-field {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: transparent;

  + .input-field {
    margin-top: 15px;
  }

  &__control {
    font: inherit;
    padding: 11px 8px;
    outline: none;
    background-color: inherit;

    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown:focus + .input-field__label,
    &:not(:placeholder-shown) + .input-field__label {
      left: 4px;
      top: 0;
      padding: 0 4px;
      background-image: linear-gradient(transparent 45%, #fff 50%, #fff 55%, transparent 60%);
      transform: translateY(-50%);
    }

    &:placeholder-shown + .input-field__label {
      left: 9px;
      top: 8px;
    }
  }

  &__label {
    position: absolute;
    pointer-events: none;
  }
}
