@import "~@evrone-erp/evrone-erp-ui/lib/css/bootstrap/imports";

.spinnerWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.spinner {
  width: 70px;
  height: 70px;
}
